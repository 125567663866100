import React, { useEffect, useState } from 'react'
import {Input, Popover, PopoverContent, PopoverTrigger, Skeleton} from "@nextui-org/react";
import { VscSymbolKeyword } from "react-icons/vsc";
import {Button} from "@nextui-org/button";
import thum from '../assets/thum.png'
import w from '../assets/w.png'
import { IoCalendar, IoDocument, IoDocuments, IoSearch } from "react-icons/io5";
import { AiFillSignal } from "react-icons/ai";
import ReactWhatsapp from 'react-whatsapp';

import { BsBookmarksFill } from "react-icons/bs";
import { FaAward } from "react-icons/fa";
import {Select, SelectItem} from "@nextui-org/react";
import { MdOutlineAdsClick } from "react-icons/md";
import { MdOutlineVerified } from "react-icons/md";
import { FaIdCard } from "react-icons/fa";
import { MdLibraryBooks } from "react-icons/md";
import { MdOutlineAccessTime } from "react-icons/md";
import { MdOutlineBookmarkBorder } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import { IoArrowForwardOutline } from "react-icons/io5";
import {RadioGroup, Radio, useRadio, VisuallyHidden, cn} from "@nextui-org/react";
import { getEuCountryList, getMainCountryList, getMedicineCountryList, getTestimonialList } from '../actions/abroadActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { FaCalendarAlt } from "react-icons/fa";
import { IoMdArrowRoundForward } from "react-icons/io";
import {Accordion, AccordionItem} from "@nextui-org/react";
import { GrFormNextLink } from "react-icons/gr";
import {today, isWeekend, getLocalTimeZone} from "@internationalized/date";
import {useLocale} from "@react-aria/i18n";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setHours, setMinutes, getDay } from 'date-fns';
import WebView from '@luxbit/react-electron-webview'
import YouTube from 'react-youtube';
import  apply  from '../assets/apply.png'
import { Faqualification, FaHandHoldingUsd, FaSearchLocation, FaWhatsapp } from "react-icons/fa";

import  verification  from '../assets/verification.png'
import  sp  from '../assets/sp.png'
import  es  from '../assets/es.png'
import parse from 'html-react-parser';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';
import sa1 from '../assets/sa1.jpg'
import sa2 from '../assets/sa2.jpg'
import sa3 from '../assets/sa3.jpg'
import sa4 from '../assets/sa4.jpg'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination, Autoplay, FreeMode } from 'swiper/modules';
import ReactPlayer from 'react-player'
import CountUp from 'react-countup';

import sdv from '../assets/sdv.mp4'
import { getEventList, getPopularCourseList, getSliderList, getSlidersList, sendBA, sendBAMail, sendSA, sendSAMail } from '../actions/courseActions';
import BANNER01 from '../assets/BANNER-01.jpg'

const CountriesScreen = () => {
  const [value, setValue] = useState(new Date());
    const [events, setEvents] = useState([
      { date: new Date(2024, 6, 7), event: 'anniversary' },
      { date: new Date(2024, 7, 13), event: 'anniversary' },
    ]);

    const onChange = (nextValue) => {
      setValue(nextValue);
    };

    const tileContent = ({ date, view }) => {
      const event = events.find(e => e.date.toDateString() === date.toDateString());
      return event && view === 'month' ? <p>{event.event}</p> : null;
    };

  // const WebView = require('@luxbit/react-electron-webview');

  const [selected, setSelected] = React.useState("london");
  const [email, setemail] = useState('')
  const [name, setname] = useState('')
  const [mNumber, setmNumber] = useState('')
  const [YOS, setYOS] = useState('')
  const [destination, setdestination] = useState('')
  const [ndestination, setndestination] = useState('')
  const [nYOS, setnYOS] = useState('')
  const [PS, setPS] = useState('')
  const [nPS, setnPS] = useState('')
  const [SI, setSI] = useState('')
  const [nSI, setnSI] = useState('')

  const [date, setDate] = useState('')
  
  useEffect(() => {
    setnYOS(YOS.anchorKey)

  }, [YOS])

  useEffect(() => {
    setnSI(SI.anchorKey)
  }, [SI])
  useEffect(() => {
    
    setnPS(PS.anchorKey)
  }, [PS])


  useEffect(() => {
    
    setndestination(destination.anchorKey)
  }, [destination])

  const dispatch = useDispatch() 
  const history = useNavigate()
  const location = useLocation()

  const mainCountryList = useSelector(state => state.mainCountryList)
  const { error:mainCountryListError, loading:mainCountryListLoaoding, countries:mainCountries } = mainCountryList

  const euCountryList = useSelector(state => state.euCountryList)
  const { error:euCountryListError, loading:euCountryListLoaoding, countries:euCountries } = euCountryList

  const medicineCountryList = useSelector(state => state.medicineCountryList)
  const { error:medicineCountryListError, loading:medicineCountryListLoaoding, countries:medicineCountries } = medicineCountryList

  const testimonialList = useSelector(state => state.testimonialList)
  const { error: testimonialListError, loading: testimonialListLoading, testimonials } = testimonialList

  const sendBA = useSelector(state => state.sendBA)
  const { error: sendBAError, loading: sendBALoading, success:BASuccess } = sendBA

  const sendSA = useSelector(state => state.sendSA)
  const { error: sendSAError, loading: sendSALoading, success:SASuccess } = sendSA

  const SliderCourseList = useSelector(state => state.SliderCourseList)
  const { list } = SliderCourseList

  const SliderList = useSelector(state => state.SliderList)
  const { list :list1} = SliderList

  const eventList = useSelector(state => state.eventList)
  const { error: eventListError, loading: eventListLoading, eventse } = eventList
  
  const popularCourseList = useSelector(state => state.popularCourseList)
  const { error: popularCourseListError, loading: popularCourseListLoading, courses } = popularCourseList

  useEffect(() => {
    if (!mainCountries){
      dispatch(getMainCountryList())
    }
 
    if (!euCountries){
      dispatch(getEuCountryList())
    }

    if(!list){
      dispatch(getSliderList())
    }

    if(!list1){
      dispatch(getSlidersList())
    }


    if (!medicineCountries){
      dispatch(getMedicineCountryList())
    }

    if(!eventse){
      dispatch(getEventList('abroad')) 
    }

    if(!courses){
      dispatch(getPopularCourseList()) 
    }


  }, [dispatch, mainCountries, euCountries, medicineCountries, eventse, list, courses])



  useEffect(() => {
    dispatch(getTestimonialList('abroad'))
  }, [])

  const [startDate, setStartDate] = useState(null);

  const filterTime = (time) => {
    const selectedTime = new Date(time);
    const hours = selectedTime.getHours();
    const day = startDate ? getDay(startDate) : null;

    // Allow times between 9 AM and 5 PM on weekdays
    if (hours < 9 || hours > 15) {
      return false;
    }

    // Allow times between 9 AM and 2 PM on Saturdays
    if (day === 6 && hours >= 13) {
      return false;
    }

    return true;
  };

  const filterDate = (date) => {
    const day = getDay(date);
    return day !== 0; // Disable all Sundays
  };

  const sendMail = () =>{
      if((mNumber!='')&& (nPS!='')&& (email!='')&& (nYOS!='')&& (nSI!='')){
        dispatch(sendSAMail({ 
            "mNumber": mNumber,
            "email": email,
            "YOS": nYOS,
            "PS": nPS,
            "SI": nSI,
        }))
        setmNumber('')
        setemail('')
        setYOS('')
        setSI('')
        setPS('')
    }
  }

  const sendMailBA = () =>{
    if((mNumber!='')&& (name!='')&& (email!='')&& (nYOS!='')&&(startDate!='')&& (ndestination!='')&& (nSI!='')){
      dispatch(sendBAMail({ 
        "mNumber": mNumber,
        "name": name,
        "email": email,
        "YOS": nYOS,
        "date": startDate, 
        "destination": ndestination,
        "SI": nSI,
    }))
    setmNumber('')
    setemail('')
    setYOS('')
    setDate('')
    setdestination('')
    setSI('')
    setname('')
    }
  }

  useEffect(() => {
    window.scroll(0,0);
  }, [location]);
 
  return ( 
    <div className='max-w-screen flex flex-col gap-12 pb-12 overflow-x-hidden pt-20 lg:pt-24'>

      <section className='w-full relative overflow-hidden'>

        <div className='w-full object-cover object-bottom '>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            modules={[ Autoplay]}
            className="h-fit w-full"
            autoplay
          >
            {
              list1?
              list1.map(i=>(
                <SwiperSlide key={i.id} className='max-h-fit md:max-h-[500px] md:h-[400px] w-full relative object-contain'>
                <img src={i.image} alt='' className='max-h-fit md:max-h-[500px] md:h-[400px] w-full object-contain md:object-cover object-center absolute z-10'/>
                <div className='h-full w-full  flex flex-col gap-2 justify-center relative z-40 bg-gradient-to-tr from-blue-950 to-blue-950/25 md:py-24'>
                  <div className='w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] flex flex-col gap-1 md:gap-2'>
                    <p className=' text-xs md:text-2xl  text-white uppercase'>{i.description}</p>
                    <p className='font-black text-xl md:text-5xl text-white uppercase'>{i.name}</p>
                    <div className='mt-4 flex gap-2'>
                      <Button className='h-[30px] text-[10px] md:h-[50px] w-fit border-white border-2 bg-transparent font-bold sm:text-xs md:text-lg text-white' radius='none'>
                        Register
                      </Button>
                      <ReactWhatsapp number="+94766778810" message="Hello, Can I get more info." className=' flex items-center justify-center px-4 gap-2 h-[30px] text-[10px] md:h-[50px] w-fit bg-red-600 font-bold sm:text-xs md:text-lg text-white' radius='none'>
                        <FaWhatsapp className='text-lg md:text-xl' />
                        <p>Contact us</p>
                      </ReactWhatsapp>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              )):
              <SwiperSlide className='max-h-fit w-full md:max-h-[500px] md:h-[400px] relative object-contain'>
                <img src={sa1} alt='' className='max-h-fit md:max-h-[500px] md:h-[400px] w-full object-contain md:object-cover object-center absolute z-10'/>
              </SwiperSlide>
            }

         
            
          </Swiper>
        </div>

        {/* <div className='h-full w-full flex items-center bg-gradient-to-tr from-blue-950 to-blue-950/25 absolute top-0 z-30'>
        <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            modules={[ Autoplay]}
            className="h-fit w-full flex items-center"
            autoplay
          >
            {
              list1?
              list1.map(i=>(
                <SwiperSlide className='max-h-fit lg:h-[400px] w-full object-cover lg:object-cover'>
                    <div className='h-full w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] flex flex-col gap-2 justify-center'>
                      <div className=' flex flex-col gap-1 md:gap-2 max-w-[500px]'>
                        <p className=' text-xs md:text-2xl  text-white uppercase'>{i.description}</p>
                        <p className='font-black text-xl md:text-5xl text-white uppercase'>{i.name}</p>
                        <div className='mt-4 flex gap-2'>
                          <Button className='h-[30px] text-[10px] md:h-[50px] w-fit border-white border-2 bg-transparent font-bold sm:text-xs md:text-lg text-white' radius='none'>
                            Register
                          </Button>
                          <ReactWhatsapp number="+94766778810" message="Hello, Can I get more info." className=' flex items-center justify-center px-4 gap-2 h-[30px] text-[10px] md:h-[50px] w-fit bg-red-600 font-bold sm:text-xs md:text-lg text-white' radius='none'>
                            <FaWhatsapp className='text-lg md:text-xl' />
                            <p>Contact us</p>
                          </ReactWhatsapp>
                        </div>
                      </div>
                    </div>
                    </SwiperSlide>
              )):
              <SwiperSlide className='max-h-fit lg:h-[400px] w-full object-cover lg:object-cover'>
              <div className='h-full w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] flex flex-col gap-2 justify-center'>
                <div className=' flex flex-col gap-2 max-w-[500px]'>
                  <p className=' text-xs md:text-2xl  text-white uppercase'>Reach you career</p>
                  <p className='font-black text-xl md:text-5xl text-white uppercase'>Learn online at your own convenience</p>
                  <div className='mt-4 flex gap-2'>
                    <Button className='h-[35px] md:h-[50px] w-fit border-white border-2 bg-transparent font-bold text-xs md:text-lg text-white' radius='none'>
                      Register
                    </Button>
                    <ReactWhatsapp number="+94766778810" message="Hello, Can I get more info." className=' flex items-center justify-center px-4 gap-2 h-[35px] md:h-[50px] w-fit bg-red-600 font-bold text-xs md:text-lg text-white' radius='none'>
                      <FaWhatsapp className='text-lg md:text-xl' />
                      <p>Contact us</p>
                    </ReactWhatsapp>
                  </div>
                </div>
              </div>
              </SwiperSlide>
            }
                     
          </Swiper>
           
        </div> */}

        </section>

      

      <section className='h-fit w-full relative overflow-hidden px-8 py-6'>
        <div className='h-fit md:h-40 w-full max-w-[1024px] mx-auto grid grid-cols-2 md:grid-cols-4 justify-center gap-2 lg:gap-6'>
          <Link to={'/abroad/about'} className='w-full h-36 flex flex-col items-center justify-center gap-2 md:gap-4 hover:bg-white text-black duration-300 cursor-pointer hover:text-[#DA0C0C] p-10 hover:p-8 rounded-[8px] bg-red-50 hover:shadow-[0px_4px_50px_rgba(0,0,0,0.075)]'>
              <img src={sp}  alt='' className='w-full h-full object-contain' />
              <p className='text-base font-bold text-red-900 px-2 text-center'>About us</p>
            </Link>
            <Link to={'/services'} className='w-full h-36 flex flex-col items-center justify-center gap-2 md:gap-4 hover:bg-white text-black duration-300 cursor-pointer hover:text-[#DA0C0C] p-10 hover:p-8 rounded-[8px] bg-red-50 hover:shadow-[0px_4px_50px_rgba(0,0,0,0.075)]'>
              <img src={es}  alt='' className='w-full h-full object-contain' />
              <p className='text-base font-bold text-red-900 px-2 text-center'>Services</p>
            </Link>
            <Link to={'/application'} className='w-full h-36 flex flex-col items-center justify-center gap-2 md:gap-4 hover:bg-white text-black duration-300 cursor-pointer hover:text-[#DA0C0C] p-10 hover:p-8 rounded-[8px] bg-red-50 hover:shadow-[0px_4px_25px_rgba(0,0,0,0.075)] '>
              <img src={apply}  alt='' className='w-full h-full object-contain' />
              <p className='text-base font-bold text-red-900 px-2 text-center'>Apply</p>
            </Link>
            <div className='w-full h-36 flex flex-col items-center justify-center gap-2 md:gap-4 hover:bg-white text-black duration-300 cursor-pointer hover:text-[#DA0C0C] p-10 hover:p-8 rounded-[8px] bg-red-50 hover:shadow-[0px_4px_50px_rgba(0,0,0,0.075)]'>
              <img src={verification}  alt='' className='w-full h-full object-contain' />
              <p className='text-base font-bold text-red-900 px-2 text-center'>Book appointment</p>
            </div>
            
        </div>
    </section>

    <section className='h-fit w-full bg-blue-600 '>
        <div className='w-full h-fit max-w-[1100px] mx-auto relative  md:gap-4 px-6 flex flex-col-reverse md:flex-row'>
            <div className='hidden md:block h-full md:w-1/2 mt-auto'>
              <img src={w} alt='' className='h-full object-cover ' />
            </div>

            <div className='h-full w-full md:w-1/2 flex flex-col justify-center px-8 gap-4 relative py-8'>
              <p className='text-2xl md:text-4xl font-bold text-white'>Register</p>
              
              {
                
                SASuccess?
                <p className='text-xs text-green-500 font-lg '>Message send succefully</p>:
                <p className='text-xs text-red-500 font-lg '>All fields are required*</p>
              }
              <div className='grid grid-cols-1 gap-6'>
                <Input isClearable  variant='flat' type='email' value={email} 
                        onChange={(e) => setemail(e.target.value)} placeholder='Email'></Input>
                <Input isClearable  variant='flat' type='number' value={mNumber} 
                        onChange={(e) => setmNumber(e.target.value)} placeholder='Mobile number' startContent={
                  <div className="pointer-events-none flex items-center">
                    <span className="text-default-400 text-small">+94</span>
                  </div>
                }></Input>
              </div>
              <div className='h-fit w-full relative '>
                <div className='absolute top-1/2  transform right-2 -translate-y-1/2'>
                  <IoCalendar/>
                </div>
                <Select 
                  className="w-full" 
                  // selectedKeys={pLocation}
                  // onSelectionChange={setPLocation}
                  variant=''
                  placeholder='Year of Study'
                  required
                  size='md'
                  selectedKeys={YOS}
                  onSelectionChange={setYOS}

              >
                  <SelectItem key='2025' className=''>
                      2025
                  </SelectItem>
                  <SelectItem key='2027' className=''>
                      2027
                  </SelectItem>
                  <SelectItem key='2028' className=''>
                      2028
                  </SelectItem>
              </Select>
              </div>
              <div className='grid grid-cols-2 gap-6'>
                <Select 
                    className="w-full" 
                    // selectedKeys={pLocation}
                    // onSelectionChange={setPLocation}
                    variant=''
                    placeholder='Preferred Study Destination'
                    required
                    size='md'
                    selectedKeys={PS}
                    onSelectionChange={setPS}
                >
                    {
                      mainCountryListLoaoding?
                      "":
                      mainCountries?
                      mainCountries.map(i => (
                        <SelectItem key={i.slug} value={i.slug} className=''>
                            {i.name}
                        </SelectItem>
                      )):''
                    }
                    {
                      euCountryListLoaoding?
                      "":
                      euCountries?
                      euCountries.map(i => (
                        <SelectItem key={i.slug} value={i.slug} className=''>
                            {i.name}
                        </SelectItem>
                      )):''
                    }

{
                      medicineCountryListLoaoding?
                      "":
                      medicineCountries?
                      medicineCountries.map(i => (
                        <SelectItem key={i.slug} value={i.slug} className=''>
                            {i.name}
                        </SelectItem>
                      )):''
                    }
                </Select>
                <Select 
                    className="w-full" 
                    // selectedKeys={pLocation}
                    // onSelectionChange={setPLocation}
                    variant=''
                    placeholder='Study Intake'
                    required
                    size='md'
                    selectedKeys={SI}
                    onSelectionChange={setSI}

                >
                    <SelectItem key='January – March' className=''>
                        January – March
                    </SelectItem>
                    <SelectItem key='July – September' className=''>
                        July – September
                    </SelectItem>
                    <SelectItem key='October – December' className=''>
                        October – December
                    </SelectItem>
                    <SelectItem key='I’m Not Sure' className=''>
                        I’m Not Sure
                    </SelectItem>
                </Select>
              </div>
              <Button variant='solid' color='danger' onClick={sendMail} endContent={
                  <GrFormNextLink />
              } className='w-fit font-medium bg-[#DA0C0C] text-white mt-4'>
                Register now
              </Button>
            </div> 
        </div>
    </section>

      <section className='h-fit w-full'>
          <div className='w-full h-fit max-w-[1100px] mx-auto bg-white relative z-10 md:gap-4 px-6'>
            <div className='flex flex-col pb-8'>
              <p className='uppercase text-xs font-semibold opacity-50 text-center'>Study Abroad</p>
              <p className='text-2xl md:text-4xl font-bold text-[#DA0C0C] text-center capitalize'>Popular  Study destination</p>
            </div>
            {
              mainCountryListLoaoding?
              <div className='w-fit mx-auto flex flex-wrap items-center justify-center gap-2 md:gap-4 '>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>
                
              </div>
              :
              mainCountries?
              <div className='w-fit mx-auto flex flex-wrap items-center justify-center gap-2 md:gap-4 '>

                {
                  mainCountries.map(i => (
                    <Link key={i.slug} to={`/countries/${i.slug}`} className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                      <div className='h-full p-6 flex flex-row justify-between'>
                        <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                          <p className='text-xl font-bold text-white'>{i.name}</p>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </div>
                        <div>
                          <IoMdArrowRoundForward className='text-xl text-white'/>
                        </div>
                      </div>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src={i.image} alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Link>
                  ))
                }

              </div>:
              ''
            }
          </div>
      </section>

      <section className='h-fit w-full'>
          <div className='w-full h-fit max-w-[1100px] mx-auto bg-white relative z-10 md:gap-4 px-6'>
            <div className='flex flex-col pb-8'>
              <p className='uppercase text-xs font-semibold opacity-50 text-center'>Study Abroad</p>
              <p className='text-2xl md:text-4xl font-bold text-[#DA0C0C] text-center capitalize'>european nation member countries study destination</p>
            </div>
            {
              euCountryListLoaoding?
              <div className='w-fit mx-auto flex flex-wrap items-center justify-center gap-2 md:gap-4 '>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>
                
              </div>
              :
              euCountries?
              <div className='w-fit mx-auto flex flex-wrap items-center justify-center gap-2 md:gap-4 '>

                {
                  euCountries.map(i => (
                    <Link key={i.slug} to={`/countries/${i.slug}`} className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                      <div className='h-full p-6 flex flex-row justify-between'>
                        <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                          <p className='text-xl font-bold text-white'>{i.name}</p>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </div>
                        <div>
                          <IoMdArrowRoundForward className='text-xl text-white'/>
                        </div>
                      </div>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src={i.image} alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Link>
                  ))
                }

              </div>:
              ''
            }
          </div>
      </section>

      <section className='h-fit w-full'>
          <div className='w-full h-fit max-w-[1100px] mx-auto bg-white relative z-10 md:gap-4 px-6'>
            <div className='flex flex-col pb-8'>
              <p className='uppercase text-xs font-semibold opacity-50 text-center'>Study Abroad</p>
              <p className='text-2xl md:text-4xl font-bold text-[#DA0C0C] text-center capitalize'>Study medicine destinations</p>
            </div>
            {
              medicineCountryListLoaoding?
              <div className='w-fit mx-auto flex flex-wrap items-center justify-center gap-2 md:gap-4 '>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>

                  <Link className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                    <div className='h-full p-6 flex flex-row justify-between'>
                      <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xl font-bold text-white'>i.name</p>
                        </Skeleton>
                        <Skeleton className='rounded-[8px]'>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </Skeleton>
                      </div>
                      <div>
                        <IoMdArrowRoundForward className='text-xl text-white'/>
                      </div>
                    </div>
                    <Skeleton className='rounded-[8px]'>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src='' alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Skeleton>
                  </Link>
                
              </div>
              :
              medicineCountries?
              <div className='w-fit mx-auto flex flex-wrap items-center justify-center gap-2 md:gap-4 '>

                {
                  medicineCountries.map(i => (
                    <Link key={i.slug} to={`/countries/${i.slug}`} className='bg-white w-[40%] sm:w-[175px] h-[125px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden duration-500'>
                      <div className='h-full p-6 flex flex-row justify-between'>
                        <div className='flex flex-col relative z-30 gap-1 mt-auto'>
                          <p className='text-xl font-bold text-white'>{i.name}</p>
                          <p className='text-xs text-gray-100 opacity-75'>Learn more</p>
                        </div>
                        <div>
                          <IoMdArrowRoundForward className='text-xl text-white'/>
                        </div>
                      </div>
                      <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                        
                        <img src={i.image} alt='' className='h-full w-full object-cover absolute z-0' />
                        <div className='h-full w-full bg-gradient-to-t from-black/75 to-transparent absolute z-30 hover:from-black/100 duration-500'></div>
                      </div>
                    </Link>
                  ))
                }

              </div>:
              ''
            }
          </div>
      </section>

      <section className='h-fit w-full relative px-8 bg-red-800'>
        <div className='h-fit w-full max-w-[1024px] mx-auto justify-center gap-6 grid grid-col-2 md:grid-cols-3 lg:grid-cols-4 bg-red-800 text-white py-6 rounded-[16px]'>
          <div className='w-full flex flex-col items-center justify-center'>
            

            <p className='text-4xl font-bold'><CountUp duration={5} end={100} />%</p>
            <p className='text-sm font-medium'>Satisfication</p>
          </div>
          <div className='w-full flex flex-col items-center justify-center'>
            <p className='text-4xl font-bold'><CountUp duration={5} end={40} />+</p>
            <p className='text-sm font-medium'>Countries</p>
          </div>
          <div className='w-full flex flex-col items-center justify-center'>
            <p className='text-4xl font-bold'><CountUp duration={5} end={300} />+</p>
            <p className='text-sm font-medium'>Universities</p>
          </div>
          <div className='w-full flex flex-col items-center justify-center'>
            <p className='text-4xl font-bold'><CountUp duration={5} end={2000} />+</p>
            <p className='text-sm font-medium'>Success Clients</p>
          </div>
        </div>
      </section>

      <section className='h-fit w-full relative overflow-hidden px-8'>
        <div className='h-fit w-full max-w-[1024px] mx-auto overflow-visible'>
            <div className='flex flex-col pb-8'>
              <p className='uppercase text-xs text-center'>News and events</p>
              <p className='text-2xl lg:text-4xl font-bold text-center'>News and events</p>
            </div>

            <div className='hidden lg:block'>
                <Swiper
                  slidesPerView={3}
                  spaceBetween={10}
                  freeMode={true}
                  
                  autoplay
                  modules={[FreeMode,  Autoplay]}
                  className="h-fit"
                >

                    

                  {
                    eventListLoading?
                    '':
                    eventse?
                    eventse.map(i=>(
                      <SwiperSlide key={i.slug} className='bg-white border-[1px] border-red-100 rounded-[8px] mb-12 w-full '>
                         <Popover placement="bottom" showArrow={true} backdrop="opaque">
                            <PopoverTrigger>
                            <div className='bg-white rounded-[8px] w-full p-4'>
                              <img src={i.image? i.image:'https://ucarecdn.com/c49a7d0c-089f-4ac3-854f-d2b2d815c01d/-/crop/750x422/0,39/-/preview/-/format/auto/-/format/auto/-/quality/smart_retina/-/resize/824x/'} alt='' className='object-cover rounded-[6px] h-[150px] w-full mx-auto hover:scale-105 duration-200' />
                              <div className='flex flex-col pt-6 gap-4'>
                                <div className='flex flex-col'>
                                
                                <p className='text-sm opacity-75'>{i.event}</p>
                                <p className='text-lg text-[#DA0C0C] font-bold'>{format(new Date(i.date), 'dd MMMM yyyy')}</p>
                                
                                </div>
                              </div>
                            </div>
                          </PopoverTrigger>
                          <PopoverContent>
                            <p className='text-sm opacity-75 max-w-[400px] w-full p-4'>{parse(i.description)}</p>
                          </PopoverContent>
                        </Popover>
                        
                      </SwiperSlide>
                    ))
                    :
                    ''
                  }
                </Swiper>
              </div>

              <div className='lg:hidden'>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  freeMode={true}
                  
                  autoplay
                  modules={[FreeMode,  Autoplay]}
                  className="h-fit"
                >

                    

                  {
                    eventListLoading?
                    '':
                    eventse?
                    eventse.map(i=>(
                      <SwiperSlide key={i.slug} className='bg-white border-[1px] border-red-100 rounded-[8px] mb-12 w-full '>
                         <Popover placement="bottom" showArrow={true} backdrop="opaque">
                            <PopoverTrigger>
                            <div className='bg-white rounded-[8px] w-full p-4'>
                              <img src={i.image? i.image:'https://ucarecdn.com/c49a7d0c-089f-4ac3-854f-d2b2d815c01d/-/crop/750x422/0,39/-/preview/-/format/auto/-/format/auto/-/quality/smart_retina/-/resize/824x/'} alt='' className='object-cover rounded-[6px] h-[150px] w-full mx-auto hover:scale-105 duration-200' />
                              <div className='flex flex-col pt-6 gap-4'>
                                <div className='flex flex-col'>
                                
                                <p className='text-sm opacity-75'>{i.event}</p>
                                <p className='text-lg text-[#DA0C0C] font-bold'>{format(new Date(i.date), 'dd MMMM yyyy')}</p>
                                
                                </div>
                              </div>
                            </div>
                          </PopoverTrigger>
                          <PopoverContent>
                            <p className='text-sm opacity-75 max-w-[400px] w-full p-4'>{parse(i.description)}</p>
                          </PopoverContent>
                        </Popover>
                        
                      </SwiperSlide>
                    ))
                    :
                    ''
                  }
                </Swiper>
              </div>

        
      


        </div>
      </section>

      <section className='h-fit max-w-full bg-white'>
        <div className='h-fit w-full flex flex-col'>
          
          <div className='w-full mx-auto h-full max-w-[1024px] grid grid-cols-1 md:grid-cols-2 gap-16 items-center text-left'>
            <div className=' w-full h-full min-h-[100%] min-w-[100%] flex items-center justify-center'>
              <iframe src="https://www.facebook.com/plugins/video.php?height=311&href=https%3A%2F%2Fwww.facebook.com%2Fiebc.lk%2Fvideos%2F1697568263891857%2F&show_text=false&width=560&t=0" frameborder="0" className='h-full w-full my-auto' allowfullscreen="true"  allowFullScreen="true"></iframe>
            {/* <ReactPlayer url={sdv} loop={true} controls={true} /> */}
              

            </div>
            <div className='max-w-screen h-fit flex flex-col gap-4 w-full max-w-md relative mx-8 px-4 md:px-0'>
              <img src='https://www.marketsquaredental.com/files/2011/08/book-now.png' alt='' className='absolute w-full h-full opacity-25 object-contain scale-110' />
              <div className='w-full flex flex-col gap-6'>
                <p className='text-2xl font-bold'>Book an appointment</p>
                {
                    
                    BASuccess?
                    <p className='text-xs text-green-500 font-lg '>Message send succefully</p>:
                    <p className='text-xs text-red-500 font-lg '>All fields are required*</p>
                  }
                <Input isClearable value={name} 
                            onChange={(e) => setname(e.target.value)}  variant='flat' type='text' placeholder='Name'></Input>
                <div className='grid grid-cols-2 gap-6'>
                  <Input isClearable value={email} 
                            onChange={(e) => setemail(e.target.value)}  variant='flat' type='email' placeholder='Email'></Input>
                  <Input isClearable  value={mNumber} 
                            onChange={(e) => setmNumber(e.target.value)} variant='flat' type='number' placeholder='Mobile number' startContent={
                    <div className="pointer-events-none flex items-center">
                      <span className="text-default-400 text-small">+94</span>
                    </div>
                  }></Input>
                </div>
                <div className='h-fit w-full relative grid grid-cols-2 gap-6'>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showTimeSelect
                    filterTime={filterTime}
                    filterDate={filterDate}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeIntervals={30}
                    minTime={setHours(setMinutes(new Date(), 0), 9)}
                    maxTime={setHours(setMinutes(new Date(), 0), 17)}
                    placeholderText="Select a date and time"
                    className='min-w-[100%] w-full flex py-[10px] px-3 rounded-[12px] text-sm outline-none cursor-pointer bg-gray-100 shadow-sm hover:opacity-50'
                  />
                  <div className='absolute top-1/2  transform right-2 -translate-y-1/2'>
                    <IoCalendar/>
                  </div>
                  <Select 
                    className="w-full" 
                    // selectedKeys={pLocation}
                    // onSelectionChange={setPLocation}
                    variant=''
                    placeholder='Year of Study'
                    required
                    size='md'
                    selectedKeys={YOS}
                    onSelectionChange={setYOS}

                >
                    <SelectItem key='2025' className=''>
                        2025
                    </SelectItem>
                    <SelectItem key='2027' className=''>
                        2027
                    </SelectItem>
                    <SelectItem key='2028' className=''>
                        2028
                    </SelectItem>
                </Select>
                </div>
                <div className='flex gap-6'>
                  <Select 
                      className="w-full" 
                      // selectedKeys={pLocation}
                      // onSelectionChange={setPLocation}
                      variant=''
                      placeholder='Preferred Study Destination'
                      required
                      size='md'
                      selectedKeys={destination}
                    onSelectionChange={setdestination}

                  >
                         {
                          mainCountryListLoaoding?
                          "":
                          mainCountries?
                          mainCountries.map(i => (
                            <SelectItem key={i.slug} value={i.slug} className=''>
                                {i.name}
                            </SelectItem>
                          )):''
                        }
                        {
                          euCountryListLoaoding?
                          "":
                          euCountries?
                          euCountries.map(i => (
                            <SelectItem key={i.slug} value={i.slug} className=''>
                                {i.name}
                            </SelectItem>
                          )):''
                        }

                        {
                          medicineCountryListLoaoding?
                          "":
                          medicineCountries?
                          medicineCountries.map(i => (
                            <SelectItem key={i.slug} value={i.slug} className=''>
                                {i.name}
                            </SelectItem>
                          )):''
                        }
                  </Select>
                  <Select 
                      className="w-full" 
                      // selectedKeys={pLocation}
                      // onSelectionChange={setPLocation}
                      variant=''
                      placeholder='Study Intake'
                      required
                      size='md'
                      selectedKeys={SI}
                    onSelectionChange={setSI}

                  >
                      <SelectItem key='January – March' className=''>
                          January – March
                      </SelectItem>
                      <SelectItem key='July – September' className=''>
                          July – September
                      </SelectItem>
                      <SelectItem key='October – December' className=''>
                          October – December
                      </SelectItem>
                      <SelectItem key='I’m Not Sure' className=''>
                          I’m Not Sure
                      </SelectItem>
                  </Select>
                </div>
                
                <Button onClick={sendMailBA} variant='solid' color='danger' endContent={
                    <GrFormNextLink />
                } className='w-fit font-medium bg-[#DA0C0C] text-white mt-4'>
                  Book now
                </Button>
              </div>             
            </div>
          </div>

        </div>
      </section>

      <section className='h-fit w-full relative overflow-hidden px-8'>
        <div className='h-fit w-full max-w-[1024px] mx-auto overflow-visible'>
          <div className='flex flex-col pb-8'>
              <p className='uppercase text-xs text-center'text-center>Our Students Testimonials</p>
              <p className='text-2xl lg:text-4xl font-bold text-center'>Study abroad Testimonial</p>
            </div>
            <div className='hidden md:block'>
              <Swiper
                slidesPerView={3}
                spaceBetween={10}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                autoplay
                modules={[FreeMode, Pagination, Autoplay]}
                className=""
              >
                
                {
                  courses?
                  courses.filter(f=> f.name=='sa').map(i =>(
                    <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
                      <div className='h-[200px] w-full relative z-10'>
                        <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
                        <iframe src={i.description} height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        </div>
                      </div>
                      <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>
                      </div>
                    </SwiperSlide>
                  )):
                  ''
                }
                
              </Swiper>
            </div>

            <div className='sm:hidden'>
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                autoplay
                modules={[FreeMode, Pagination, Autoplay]}
                className=""
              >
                {
                  courses?
                  courses.map(i =>(
                    <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
                      <div className='h-[200px] w-full relative z-10'>
                        <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
                        <iframe src="https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F724979536505482%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        </div>
                      </div>
                      <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>
                      </div>
                    </SwiperSlide>
                  )):
                  ''
                }
              </Swiper>
            </div>

        </div>
      </section>

  
      <section className='lg:h-[400px] w-full relative overflow-hidden'>

        <div className='min-h-fit lg:h-[400px] w-full object-cover object-bottom relative z-10'>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            modules={[ Autoplay]}
            className="h-fit w-full"
            autoplay
          >
            {
              list?
              list.map(i =>(
                <SwiperSlide key={i.id} className='h-[500px] max-h-fit lg:h-[400px] w-full object-cover lg:object-cover'>
                  <img src={i.image} alt='' className='h-[500px] max-h-fit lg:h-[400px] w-full object-cover lg:object-cover object-center'/>
                </SwiperSlide>
              )):
              ''
            }
            

          
            
          </Swiper>
        </div>
        <div className='h-full w-full flex items-center bg-gradient-to-tr from-blue-950 to-blue-950/25 absolute top-0 z-30'>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            modules={[ Autoplay]}
            className="h-fit w-full flex items-center"
            autoplay
          >

        {list && list.length > 0 ? (
        list.map((i) => (
          <SwiperSlide
            key={i.id}
            className='max-h-fit lg:h-[500px] w-full object-cover lg:object-cover'
          >
            <div className='h-full w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] flex flex-col gap-2 justify-center'>
              <div className='h-fit flex flex-col gap-2 max-w-[500px]'>
                <p className='font-black text-xl md:text-5xl text-white uppercase'>
                {i.desitination}
                </p>
                <p className='font-black text-white uppercase'>
                {i.intake}
                </p>
                <ul className='text-sm text-white flex flex-col pl-6'>
                  {i.description ? (
                    i.description.split('-').map((point, index) => (
                      <li className='list-disc' key={index}>{point}</li>
                    ))
                  ) : (
                    <li></li>
                  )}
                </ul>
                <div className='mt-4 flex flex-wrap gap-2'>
                  <ReactWhatsapp
                    key={i.id}
                    number="+94766778810"
                    message={`Hello, Can I get more info about the ${i.intake}?`}
                    className='flex items-center justify-center px-4 gap-2 h-[30px] w-fit bg-transparent border-[2px] border-red-600 font-bold text-sm md:text-lg text-white'
                  >
                    <FaWhatsapp className=' text-lg md:text-xl' />
                    <p className='text-[10px]'>Contact us</p>
                  </ReactWhatsapp>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))
        ) : (
        ''
        )}
            
            

          </Swiper>
        </div>
      </section>

      <section className='h-fit w-full relative overflow-hidden px-8'>
        <div className='h-fit w-full max-w-[1024px] mx-auto overflow-visible'>
          {/* <div className='flex flex-col pb-8'>
              <p className='uppercase text-xs text-center'text-center>Our Students Testimonials</p>
              <p className='text-2xl lg:text-4xl font-bold text-center'>Study abroad Testimonial</p>
            </div> */}
            <div className='hidden md:block'>
              <Swiper
                slidesPerView={3}
                spaceBetween={10}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                autoplay
                modules={[FreeMode, Pagination, Autoplay]}
                className=""
              >
                
                {
                  courses?
                  courses.filter(f=> f.name=='sv').map(i =>(
                    <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
                      <div className='h-[200px] w-full relative z-10'>
                        <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
                        <iframe src={i.description} height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        </div>
                      </div>
                      <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>
                      </div>
                    </SwiperSlide>
                  )):
                  ''
                }
                
              </Swiper>
            </div>

            <div className='sm:hidden'>
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                autoplay
                modules={[FreeMode, Pagination, Autoplay]}
                className=""
              >
                {
                  courses?
                  courses.map(i =>(
                    <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
                      <div className='h-[200px] w-full relative z-10'>
                        <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
                        <iframe src="https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F724979536505482%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        </div>
                      </div>
                      <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>
                      </div>
                    </SwiperSlide>
                  )):
                  ''
                }
              </Swiper>
            </div>

        </div>
      </section>
      
    </div>

  )
}

export default CountriesScreen














// <section className='h-fit w-full relative overflow-hidden px-8'>
// <div className='h-fit w-full max-w-[1024px] mx-auto overflow-visible'>
//     {/* <div className='flex flex-col pb-8'>
//       <p className='uppercase text-xs text-center'>Resources</p>
//       <p className='text-2xl lg:text-4xl font-bold text-center'>Our Resources</p>
//     </div> */}

//     <div className='hidden md:block'>
//       <Swiper
//         slidesPerView={3}
//         spaceBetween={10}
//         freeMode={true}
//         pagination={{
//           clickable: true,
//         }}
//         autoplay
//         modules={[FreeMode, Pagination, Autoplay]}
//         className=""
//       >
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F724979536505482%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F787360559926806%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F1063711408132096%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F1438798656686721%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>

//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F809322143721464%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F308333668443585%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F585327600327936%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F831771447883217%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>

//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F856088048932476%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F469373541830598%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F650057759927476%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F1097307701397738%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F6722428004493216%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F3272347499745828%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         {/* <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//                 <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2Fiebc.lk%2Fvideos%2F390059596804608%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//                 <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2Fiebc.lk%2Fvideos%2F390059596804608%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide> */}
//       </Swiper>
//     </div>

//     <div className='hidden sm:block md:hidden'>
//       <Swiper
//         slidesPerView={2}
//         spaceBetween={10}
//         freeMode={true}
//         pagination={{
//           clickable: true,
//         }}
//         autoplay
//         modules={[FreeMode, Pagination, Autoplay]}
//         className=""
//       >
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//                 <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2Fiebc.lk%2Fvideos%2F390059596804608%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//               <div className='h-[200px] w-full relative z-10'>
//                 <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//                     <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2Fiebc.lk%2Fvideos%2F390059596804608%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//                 </div>
//               </div>
//               <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//               </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//                 <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2Fiebc.lk%2Fvideos%2F390059596804608%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         {/* <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//                 <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2Fiebc.lk%2Fvideos%2F390059596804608%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//                 <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2Fiebc.lk%2Fvideos%2F390059596804608%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide> */}
//       </Swiper>
//     </div>

//     <div className='sm:hidden'>
//       <Swiper
//         slidesPerView={1}
//         spaceBetween={10}
//         freeMode={true}
//         pagination={{
//           clickable: true,
//         }}
//         autoplay
//         modules={[FreeMode, Pagination, Autoplay]}
//         className=""
//       >
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F724979536505482%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F787360559926806%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F1063711408132096%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F1438798656686721%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>

//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F809322143721464%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F308333668443585%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F585327600327936%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F831771447883217%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>

//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F856088048932476%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F469373541830598%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F650057759927476%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F1097307701397738%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=316&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F6722428004493216%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//             <iframe src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fweb.facebook.com%2FIEBCGlobal%2Fvideos%2F3272347499745828%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         {/* <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//                 <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2Fiebc.lk%2Fvideos%2F390059596804608%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//                 <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2Fiebc.lk%2Fvideos%2F390059596804608%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide className='h-[300px] w-full relative mt-4 mb-12'>
//           <div className='h-[200px] w-full relative z-10'>
//             <div className='h-fit w-[90%] bg-red-50 mx-auto relative z-10 rounded-[8px] overflow-hidden'>
//                 <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2Fiebc.lk%2Fvideos%2F390059596804608%2F&show_text=false&width=560&t=0" height={'100%'} width={'full'} className='w-full h-full object-cover' frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
//             </div>
//           </div>
//           <div className='w-full h-[100px] rounded-[8px] bg-[#DA0C0C] absolute bottom-0 -z-30'>

//           </div>
//         </SwiperSlide> */}
//       </Swiper>
//     </div>

// </div>
// </section>


//  <section>
//         <div>
//           <div className='w-full h-fit max-w-[1100px] mx-auto bg-white bg-red-50 shadow-[0px_4px_25px_rgba(0,0,0,0.05)] -mt-[125px] relative z-10 md:gap-4 p-6 rounded-[16px]'>
//             <div className='w-full mx-auto flex items-center justify-around '>

//               <div className='flex flex-col gap-2 items-center justify-center'>
//                 <IoDocuments className='text-2xl text-[#DA0C0C]'/>
//                 <p className='text-lg font-semibold text-[#DA0C0C]'>Apply online</p>
//               </div>

//               <div className='flex flex-col gap-2 items-center justify-center'>
//                 <BiSolidMessageSquareDetail  className='text-2xl text-[#DA0C0C]'/>
//                 <p className='text-lg font-semibold text-[#DA0C0C]'>About Us</p>
//               </div>

//               <div className='flex flex-col gap-2 items-center justify-center'>
//                 <FaCalendarAlt  className='text-2xl text-[#DA0C0C]'/>
//                 <p className='text-lg font-semibold text-[#DA0C0C]'>Calender</p>
//               </div>

                            
//             {
//               loading?
//               "":
//               countries?
//               countries.map(i => (
//                 <Link key={i.slug} to={`/countries/${i.slug}`} className='bg-white min-w-[300px] max-w-[400px] h-[200px] border-1 border-gray-200 gap-8 flex flex-col rounded-[8px] relative overflow-hidden hover:scale-105 duration-500'>
//                   <div className='h-full p-6 flex flex-col justify-between'>
//                     <div className='flex flex-col relative z-30'>
//                       <p className='text-4xl font-bold text-white'>{i.name}</p>
//                     </div>
//                     <div className='flex items-center gap-4 relative z-30'>
//                       <Button radius='full' isIconOnly variant='solid' className='bg-red-600 text-white bg-red-50 shadow-[0px_4px_25px_rgba(0,0,0,0.05)] ' startContent={<IoArrowForwardOutline/>}>

//                       </Button>
//                       <p className='text-xs text-gray-100'>Learn more</p>
//                     </div>
//                   </div>
//                   <div className='h-full w-full bg-red-50 absolute rounded-full flex items-start justify-start z-0'>
                    
//                     <img src={i.image} alt='' className='h-full w-full object-cover absolute z-0' />
//                     <div className='h-full w-full bg-gradient-to-t from-black to-transparent absolute z-30'></div>
//                   </div>
//                 </Link>
//               ))
//               :
//               ""
//             }

//             </div>
//           </div>
//         </div>
//       </section>